import '../../assets/css/Policy.css';

const Policy = () => {
    return (
        <div className="policy-overall">
            <div>
                <div className="policy-heading-wrapper">
                    <div className="policy-title">
                        <h2>Privacy Policy</h2>
                    </div>
                    <div className="policy-context">
                        <p>Privacy Policy for Website &amp; Mobile Application</p>
                    </div>
                </div>
                <div className="policy-holder">
                    <div className="container">
                        <p>Effective Date: April 1, 2022</p>
                        <p>Applicable To: <a href="https://transitonline.app">www.transitonline.app</a> and the Transit Mobile Application</p>


                        <p><br/><br/><strong>1. DEFINITIONS</strong></p>
                        <p>“<strong>Data Controller</strong>” means the publisher and owner of the Website and Mobile Application and the party also responsible for the collection of data.</p>
                        <p>“<strong>Data Processing Officer</strong>” means the party responsible for overseeing data protection strategy and implementation to ensure compliance.</p>
                        <p>“<strong>Content</strong>” means any content, writing, images, audiovisual content or other information published on this Website.</p>
                        <p>“<strong>Parties</strong>” means both You (the user of the Service) and the Data Controller: Transit-Tech Limited.</p>
                        <p>“<strong>Personal Data</strong>” means any personal information collected for You in relation with your use of this service which is capable of identifying You.</p>
                        <p>“<strong>Service</strong>” means the Website and Mobile Application, which is known as: <a href='https://transitonline.app'>www.transitonline.app</a> and TRANSIT (mobile application) including all pages, subpages, all blogs, forums and other connected internet content whatsoever.</p>
                        <p>“<strong>Services</strong>” means the services offered on the Website and Transit Mobile Application.</p>
                        <p>“<strong>Third Party Service Provider</strong>” means a party or parties who are contracted by the owner/data controller for the purposes of processing the personal data of user.</p>
                        <p>“<strong>User Content</strong>” means any audio, video, text, images or other material users choose to display on this Website and the Transit Mobile Application.</p>
                        <p>“<strong>You, Yours</strong>” means the user of this Website and the Transit Mobile Application.</p>

                        <p><br/><br/><strong>2. INTRODUCTION</strong></p>
                        <p>This Privacy Policy is designed to inform You about the Personal Data we collect, how we collect this data, the uses of the data and Your rights relating to the Personal Data when You use this Service or purchase the Services offered on the Website and Transit Mobile Application.</p>
                        <p>We are committed to protecting your Personal Data while You use this Website and the Transit Mobile Application.</p>
                        <p>By continuing to use our Website and the Transit Mobile Application, You acknowledge that You have reviewed the Privacy Policy and agree to its terms. This also means that You have consented to the use of Your Personal Data and has accepted the applicable disclosures.</p>

                        <p><strong><br/><br/>3. CONTACT</strong></p>
                        <p>The operator who is also the party responsible for the collection of data is as follows: Transit-Tech Limited and can be contacted as follows:</p>
                        <p><a href="mailto:hello@transitonline.app">hello@transitonline.app</a><br/><a href="tel:+234-818-471-3840">+234-818-471-3840</a></p>
                        <p>The Data Protection Officer is as follows: Transit-Tech Limited Admin and can be contacted as follows:</p>
                        <p><a href="mailto:hello@transitonline.app">hello@transitonline.app</a><br/><a href="tel:+234-818-471-3840">+234-818-471-3840</a></p>
                        
                        <p><br/><br/><strong>4. THE PERSONAL DATA WE COLLECT FROM YOU</strong></p>
                        <p>We collect various information to enable us provide good Service to all our users. Depending on how our Service will be used, the different types of Personal Data we collect are as follows:</p>
                        <p>A.<strong> For registered users</strong>: During the process of Your registration we may collect the following information:</p>
                        <p>Name, Email, Address, Phone Number, and Zip Code.</p>
                        <p>We may also require other information in relation to:</p>
                        <p>(I) Your interaction with our representatives;</p>
                        <p>(II) receiving notifications by text message or email about marketing;</p>
                        <p>(III) receiving general emails from us.</p>
                        <p>(IV) commenting on our Content or other User Content on our Website and Mobile Application.</p>
                        <p>(V) the purchases You make.</p>
                        <p>(VI) the following forms of activities:</p>
                        <p>VII) processing online payments for paid services/consultations/advisory.</p>
                        <p>B.<strong> For unregistered users</strong>: We will collect passive information from all registered and unregistered users. This information includes cookies, IP address information, location information and certain browser information.</p>
                        <p>C.<strong> Sales and billing information</strong>: We may collect Your credit and/or debit card information, which includes Your card number, password, etc. Your billing address, Your contact address and other information required for Your purchases.</p>
                        <p>D.<strong> User experience</strong>: From time to time we may also request certain Personal Data that may be necessary to improve our Service and the Services we offer for sale on the Website and Transit Mobile Application.</p>
                        
                        <p><strong><br/><br/>5. THE PERSONAL DATA WE COLLECT AS YOU USE OUR SERVICE</strong></p>
                        <p>We use the following to collect Personal Data from You:</p>
                        <p><strong>Cookies</strong>: We use the data collected by the cookies to offer You the best experience on our Website. Cookies are information stored on Your browser when You visit our Website or use a social network with Your PC, Smartphone or Tablets. They contain various data which includes the name of the server from which it comes, the numeric identifier, etc. The types of cookies we use are as follows:</p>
                        <p>(I) <strong>Technical cookies</strong>: These cookies are essential for the correct functioning of our Website and to provide the Service required to our users.</p>
                        <p>(II) <strong>Third party cookies</strong>: While using our Service, You may receive cookies from websites managed by other organizations. Third party analytical cookies may also be installed. Third party analytical cookies are used to detect information on user behaviour on our Website. This is placed in order to monitor the performance and improve the usability of this Website.</p>
                        <p>(III) <strong>Support in configuring your browser</strong>: You can manage cookie through the settings of Your browser on Your device. However, deleting cookies from Your browser may remove the preferences You have set for this Website.</p>
                        <p><strong>Log Data</strong>: We also use log files which store automatic information collected when users visit this Website. The log data which may be collected are as follows:</p>
                        <p>(I) the domain and host from which You access the Website;</p>
                        <p>(II) name of the Internet Service Provider (ISP);</p>
                        <p>(III) date and time of visit;</p>
                        <p>(IV) Your computer operating system and browser software;</p>
                        <p>(V) web pages visited and the duration and frequency of visits;</p>
                        <p>(VI) internet protocol (IP) address.</p>
                        
                        <p><strong><br/><br/>6. THIRD PARTIES</strong></p>
                        <p>We may utilize third party service providers, from time to time to help in processing Your Personal Data and help us with our Website and Mobile Application.</p>
                        <p>We may use Third Party Service Providers for the following services in relation to our Website and Mobile Application:</p>
                        <p>Payment processing</p>
                        <p>We may also share Your Personal Data with the following third parties:</p>
                        <p>Paystack</p>
                        <p>We share Your Personal Data with third parties in order to protect the rights, properties or safety of us, the users or as otherwise required by law.</p>
                        
                        <p><strong><br/><br/>7. STORAGE OF PERSONAL DATA</strong></p>
                        <p>We take the security of the Personal Data we collect very seriously and we take reasonable measures to reduce the risk of accidental destruction, loss or unauthorized access to such information. However, please note that no system involving the transmission of information via electronic storage systems or the internet is completely secure.</p>
                        <p>The Personal Data and any other information we have about You may be stored for such period as we may determine until You terminate Your account with us or You withdraw Your consent.</p>
                        <p>Note that You can withdraw Your consent to store Your Personal Data at any time. Once this is done, all Personal Data and information we have about You will be deleted.</p>
                        
                        <p><strong><br/><br/>8. PURPOSE OF PROCESSING PERSONAL DATA</strong></p>
                        <p>We collect and use the Personal Data that we collect for the following reasons:</p>
                        <p>(I) to provide our Service, maintain and make improvement to the Service we provide to You;</p>
                        <p>(II) to develop new Services on the Website and Transit Mobile Application;</p>
                        <p>(III) to provide personalized Service to You including making recommendations and providing personalized content;</p>
                        <p>(IV) to provide customer service to You;</p>
                        <p>(V) to provide analytics and measurement to understand how our Service is used.<br/></p>
                        
                        <p><br/><br/><strong>9. PROTECTION OF PERSONAL DATA</strong></p>
                        <p>Our Service is built with strong security features that continuously protects Your Personal Data. Our security features help us detect and block security threats. If we detect any security risk, we may inform You and guide You through steps to stay protected.</p>
                        <p>We use the following security features to protect Your Personal Data from any security breach or harm:</p>
                        <p>SSL – Secure Sockets Layer to protect all personal and card details entered on our site and Mobile Application.<br/>Security Audits Application – Tracks and report all system and system files changes.<br/>Web Application Firewall – Protects against attacks, brute force, SQL Injection, and Cross Site Scripting</p>
                        
                        <p><br/><br/><strong>10. DISCLOSURE OF PERSONAL DATA</strong></p>
                        <p><strong>When You share Your Personal Data</strong></p>
                        <p>Our Service enables You to share information with other users. However, You have control over how You share this information. When You upload any content on our Website and Mobile Application, Your content becomes accessible to other users.</p>
                        <p><strong>When we share Your Personal Data</strong></p>
                        <p>We do not disclose Your Personal Data except for any of the following reasons:</p>
                        <p>(I) if You have granted us the permission to do so: We will disclose Your Personal Data where we have received Your unequivocal consent and permission to do so. Such consent may be withdrawn at any time;</p>
                        <p>(II) for the purposes of processing Your Personal Data: We may disclose Your Personal Data to trusted businesses or persons for the purpose of processing Your Personal Data for us, based on our instruction and in compliance with our Privacy Policy;</p>
                        <p>(IV) Any other reason as may be necessary for the operation of our Website and Mobile Application.</p>
                        
                        <p><br/><br/><strong>11. USER CONTENT</strong></p>
                        <p>We may allow users to post their content or information on this. These contents include audio, video, updates, articles, images or other materials users choose to display on the. Please note that any material which You may post is made accessible to the entire public and will not be regulated by this Privacy Policy.</p>
                        
                        <p><strong><br/><br/>12. LINKS TO THIRD PARTY SITES/SERVICES</strong></p>
                        <p>The Website and Mobile Application may contain links to other websites which we believe may offer useful information. This linked websites are not under our control and this Privacy Policy does not apply to these websites. We suggest that You contact those websites directly for information on their privacy policy, security, data collection and distribution policies.</p>
                        
                        <p><br/><br/><strong>13. ACCESSING, MODIFYING AND DELETING YOUR PERSONAL DATA</strong></p>
                        <p>If you wish to access, review, modify any information we have about You, You may do so by simply contacting us on the following email address: <a href="mailto:hello@transitonline.app">hello@transitonline.app</a>. You may also request that we delete any information belonging to You that we have stored.</p>
                        <p>If You wish to delete any information belonging to You, You may do so by using the following steps:</p>
                        <p>sending an email to <a href="mailto:hello@transitonline.app">hello@transitonline.app</a> with corresponding message as subject.</p>
                        
                        <p><br/><br/><strong>14. YOUR RIGHTS</strong></p>
                        <p>Your rights in relation to Your Personal Data are as follows:</p>
                        <p>(I) the right to have access to Your Personal Data;</p>
                        <p>(II) the right to be informed about the processing of Your Personal Data;</p>
                        <p>(III) the right to rectify any inaccurate Personal Data or any information about You;</p>
                        <p>(IV) the right to review, modify or erase Your Personal Data and any other information we have about You;</p>
                        <p>(V) the right to restrict the processing of Your Personal Data;</p>
                        <p>(VI) the right to block Personal Data processing in violation of any law;</p>
                        <p>(VII) the right to be informed about any rectification or erasure of Personal Data or restriction of any processing carried out;</p>
                        <p>(VIII) the right to the portability of Your Personal Data; and</p>
                        <p>(IX) the right to lodge a complaint to a supervisory authority within Nigeria.</p>
                        
                        <p><strong><br/><br/>15. CONTACT INFORMATION</strong></p>
                        <p>If You have any questions regarding this Privacy Policy or the Personal Data we collect or if You wish to make any comments or complaints about anything related to this Privacy Policy, please contact us at the following email address: <a href="mailto:hello@transitonline.app">hello@transitonline.app</a>.</p>
                    </div>
                </div>
            </div>
         </div>
    )
}

export default Policy;