import React, {useState} from 'react';

import '../../assets/css/Newsletter.css';


const CustomForm = ({ status, message, onValidated,onSubmitted }) => {
    const [disable, setDisable] = useState(true)
    let email, name, 
    emailFormat = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/;

    const handleChange = () =>{
        if( email.value !== '' && name.value !== '' && email.value.match(emailFormat)){
            setDisable(false)
        }else{
            setDisable(true)
        }
    }

    const submit = async  () =>{
        if(email || name) {
            await onValidated ({
                EMAIL: email.value,
                FNAME: name.value
            },
            {email,name}
            )
        };
        setDisable(true)
    }

    return (
        <div className="item newsletter-input">
            
            <div className="input-form">
                <input onChange={handleChange} ref={node => (name = node)} type="text" placeholder="Your First Name" />
                <input onChange={handleChange} ref={node => (email = node)} type="email" placeholder="Your Email" />
            </div>

            <button type="button" disabled={disable} style={disable ? {backgroundColor:'#a9dff5'} : null} className="newsletter-button" onClick={submit}>Get Early Access</button>
            
            {status === "sending" && <div className="status-message sendng">sending...</div>}
            {status === "error" && (<div className="status-message error" dangerouslySetInnerHTML={{ __html: message[0] === '0'? message.split('-')[1]: message }}/>)}
            {status === "success" && ( <div className="status-message success" dangerouslySetInnerHTML={{ __html: message }} /> )}
        </div>
      );
}

export default CustomForm
