import Carousel from 'react-elastic-carousel';
// import Slider from 'infinite-react-carousel'
import '../../assets/css/Screenshots.css';
// import screenshot1 from '../../assets/images/screenshot-01.png'
// import screenshot2 from '../../assets/images/screenshot-02.png'
// import screenshot3 from'../../assets/images/screenshot-03.png'
import screenshot4 from '../../assets/images/screenshot-04.png'
import screenshot5 from '../../assets/images/screenshot-05.png'
import screenshot6 from'../../assets/images/screenshot-06.png'


const Screenshots = () => {
        let breakPoints = [
            // { width: 1, itemsToShow: 1 },
            { width: 400, itemsToShow: 1 },
            { width: 594, itemsToShow: 2 },
            { width: 694, itemsToShow: 3 },
            { width: 767, itemsToShow: 3 },
            { width: 991, itemsToShow: 4 }

      ]

    const items = [
        { img: screenshot4 },
        { img: screenshot5 },
        { img: screenshot6 },
        // { img: screenshot1 },
        // { img: screenshot2 },
        // { img: screenshot3 },
    ]

    return (
        <div className="container">
            <div className="screenshots-main-container">
                <div className="screenshots-heading-wrapper">
                    <h1 className="heading item">App Screenshots</h1>
                    <p className="item">Specifically designed and developed with you in mind, to help you move better, elegantly and safely, and for the peculiarities of many public transportation systems.</p>  
                </div>
                <Carousel
                breakPoints={breakPoints}
                    itemsToShow={4}
                    showArrows={false}
                    autoPlaySpeed={1000}
                    enableAutoPlay={true}
                >
                    {items.map((item,index) => <div key={index}>
                        <img className="screenshot-img" src={item.img} alt='screenshot' />
                        </div>)}
                </Carousel>

                {/* <Slider className="screenshots-slider" dotsClass="carousel-dots" slidesToShow={noOfSlides} { ...settings }>
                    {items.map( (item, index) => (<div key={index}>
                                <img className="screenshot-img" src={item.img} alt='screenshot' />
                            </div>))}    
                </Slider> */}
            </div>
        </div>
        
    )
};

export default Screenshots;


