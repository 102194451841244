import React, { useEffect, useRef } from 'react';
import '../../assets/css/Feature.css';
import cityRoutes from '../../assets/icons/location_markers.svg';
import waitTime from '../../assets/icons/wait_time.svg';
import zeroStress from '../../assets/icons/unavailable.svg';
import safeFriendly from '../../assets/icons/rating.svg';
import guarantee from '../../assets/icons/shield_security2.svg';
import securePayment from '../../assets/icons/secure_payment.svg';

const Feature=({changeBg})=>{
  const featureRef = useRef(null)

  useEffect(()=>{
    if(changeBg){
        featureRef.current.style.background = "#383838"
    } else{
        featureRef.current.style.background = `-webkit-linear-gradient(top, #fff 0, #F1F1F1 74%, #F1F1F1 100%)`
    }
})

  return(
    <div ref={featureRef} className="feature-overall">
      <div className='container'>
        <div className="feature-heading">
          <h1 className="feature-title">Our App Features</h1>
            <p className="feature-text">Exciting features designed with you in mind, to give you the deserved comfort, safety, and guarantee you need while on the move.</p>
        </div>
        <div className="feature-idea-container">
        <div className="feature-idea-box">
          <div className="image-holder">
            <img src={cityRoutes} alt="multiple city routes" />
          </div>
          <div className="context">
            <h3>Multiple City Routes</h3>
            <p>Access to seamlessly book/schedule a ride within minutes on various city routes and get picked up.</p>
          </div>
        </div>
        <div className="feature-idea-box">
          <div className="image-holder">
            <img src={waitTime} alt="short wait times" />
          </div>
          <div className="context">
            <h3>Shorter Wait Times</h3>
            <p>Comfy rides in minutes; Track to know when your bus arrives and <em>re-write your commute experience</em>.</p>
          </div>
        </div>
        <div className="feature-idea-box">
          <div className="image-holder">
            <img src={zeroStress} alt="zero stress" />
          </div>
          <div className="context">
            <h3>Zero Stress</h3>
            <p>Our goal is to get rid of public transit related stress; Actually, <em>your productivity on STERIODS!</em></p>
          </div>
        </div>
        <div className="feature-idea-box">
          <div className="image-holder">
            <img src={safeFriendly} alt="safe and friendly" />
          </div>
          <div className="context">
            <h3>Safe &amp; Friendly</h3>
            <p>Best trained, courteous, insured, and licensed drivers to provide you a safe, reliable, and true <em>Transit Experience</em>.</p>
          </div>
        </div>
        <div className="feature-idea-box">
          <div className="image-holder">
            <img src={guarantee} alt="quality guarantee" />
          </div>
          <div className="context">
            <h3>Superior Guarantee</h3>
            <p>Highly maintained vehicles to convey you hassle-free. Plus, top-notch contigency plans for uncertainties during your trip.</p>
          </div>
        </div>
        <div className="feature-idea-box">
          <div className="image-holder">
            <img src={securePayment} alt="secure and transparent" />
          </div>
          <div className="context">
            <h3>Secure &amp; Transparent</h3>
            <p>Seamless card payments to eliminate delays after each trip. You'll instantly receive trip receipt via email.</p>
          </div>
        </div>
      </div>
      </div>
      <div className="space-divider"></div>
    </div>
  );
}

export default Feature;