import '../../assets/css/Policy.css';

const Terms = () => {
    return (
        <div className="policy-overall">
            <div>
                <div className="policy-heading-wrapper">
                    <div className="policy-title">
                        <h2>Terms of Service</h2>
                    </div>
                    <div className="policy-context">
                        <p>Terms of Service for Website &amp; Mobile Applicatiion </p>
                    </div>
                </div>
                <div className="policy-holder">
                    <div className="container">
                        <p>Effective Date: April 1, 2022</p>
                        <p>Applicable To: <a href="https://transitonline.app">www.transitonline.app</a> and the Transit Mobile Application</p>

                        <p><br/><br/>These terms of service apply to You, the user of this website (including any sub-domains, unless expressly excluded by their own terms and conditions) &amp; the Transit Mobile Application and Transit-Tech Limited, the owner and operator of the following website and mobile application: Transit (the “<strong>Website &amp; Mobile Application</strong>“), with the following URL web address: <a href="https://transitonline.app">www.transitonline.app</a> and mobile app: TRANSIT.</p>
                        <p><strong><br/>PLEASE READ THE TERMS OF SERVICE CAREFULLY</strong> as they affect Your legal right.</p>

                        <p><strong><br/><br/>1. Definitions</strong></p>
                        <p>“<strong>Parties</strong>” means both You (the user of the Service) and the Owner of this Service.</p>
                        <p>“<strong>Content</strong>” means any content, writing, images, audiovisual content or other information published on this Service.</p>
                        <p>“<strong>Your Content</strong>” means any audio, video, text, images or other material You choose to display on this Website &amp; Mobile Application subject to the restrictions provided in this Agreement.</p>
                        <p>“<strong>Materials</strong>” means any materials, information or documentation that we may provide to You in connection with Your use of the Products including documentation, data, information developed any use and other materials which may assist in Your use of the Goods or Service.</p>
                        <p>“<strong>Terms</strong>” means these terms and conditions.</p>
                        <p>“<strong>Service</strong>” means the Website &amp; Mobile Application including all pages, subpages, all blogs, forums and other connected internet content whatsoever.</p>

                        <p><strong><br/><br/>2. About this Website &amp; the Mobile Application</strong></p>
                        <p>This Website &amp; the Mobile Application is comprised of various web and application pages operated by Transit-Tech Limited.</p>
                        <p>This Service is offered to You upon Your acceptance of the Terms, conditions, notices hereinafter contained. Your use of this Service constitutes Your agreement to all the Terms contained herein.</p>

                        <p><strong><br/><br/>3. Agreement</strong></p>
                        <p>a. By using this Website &amp; the Mobile Application, You acknowledge that You have reviewed, considered the Terms of this Agreement and understand same and agree to be bound by it. If You u do not agree with these Terms or do not intend to be bound by it, You must quit the use of this Website &amp; the Mobile Application immediately. In addition, when using these Service, You shall be subject to any posted guidelines or rules applicable to such services. Accordingly, any participation in this Service shall constitute acceptance of this Agreement.</p>
                        <p>b. By using this Website &amp; the Mobile Application and agreeing to these Terms, You represent and warrant that You have the legal capacity to accept these Terms.</p>

                        <p><strong><br/><br/>4. Acceptable Use</strong></p>
                        <p>a. You hereby grant You the license to use our Service for Your personal, non-commercial use to retrieve, display and view the Content on a computer screen.</p>
                        <p>b. The license created under these Terms is limited, non-exclusive, non-transferable and revocable.</p>
                        <p>c. You agree that You will not use the Contents or Materials for any other purpose which may be contrary to your license to use this Service.</p>
                        <p>d. Any unauthorized use by You shall terminate the permission or license granted by this Website &amp; the Mobile Application.</p>

                        <p><strong><br/><br/>5. Prohibited Use</strong></p>
                        <p>a. You are expressly prohibited from collecting, downloading, copying or otherwise communicating with other Users from the Website &amp; Mobile Application<strong>.</strong></p>
                        <p>b. You agree not to use the Service in the following manner:</p>
                        <p>(I). to harass, abuse or threaten others or otherwise violate any person’s legal rights;</p>
                        <p>(II). to perpetrate fraud;</p>
                        <p>(III). to create or transmit unnecessary spam to any person or URL;</p>
                        <p>(IV). to post, transmit or cause to be posted or transmitted, any communication or solicitation designed to obtain password, account, or private information of other Users or persons;</p>
                        <p>(V). to post copyrighted content which does not belong to You and without obtaining the prior consent of the author;</p>
                        <p>(VI). to use robot, spider, scraper or other automated means to access this Service without obtaining the prior consent of the Owner;</p>
                        <p>(VII). to engage in or create any unlawful gambling, sweepstakes, or scheme;</p>
                        <p>(VIII). publishing or distributing any obscene or defamatory material;</p>
                        <p>(IX). using this Service in any way that impacts user access to the Website &amp; Mobile Application;</p>
                        <p>(X). to engage in advertisement or solicit any User to buy or sell products or services without obtaining the prior consent of the Owner;</p>
                        <p>(XI). disseminating computer viruses or other software;</p>
                        <p>(XII). violating any intellectual property rights of the Owner or any third party;</p>
                        <p>(XIII). to use the Website &amp; Mobile Application or any of the Services for illegal spam activities.</p>
                        <p>c. Additionally, you agree that You will not do as follows:</p>
                        <p>(I). interfere or attempt to interfere with the proper working of this Website &amp; the Mobile Application; or</p>
                        <p>(II). bypass any measures we may use to prevent or restrict access to this Website &amp; the Mobile Application;</p>
                        <p>(III). to interfere with or circumvent the security features of this Service;</p>
                        <p>(IV). to damage, disable, overburden or impair this Service or any other person’s use of this Service.</p>
                        <p>(V). to use this Service contrary to the applicable laws and regulations or in a way that causes, or may cause harm to this Website &amp; the Mobile Application, any person or business entity.</p>
                        <p>d. The Owner has the authority to review all content posted by the Users on this Website &amp; the Mobile Application and we reserve the right to terminate Your use of the Service for violating any of the prohibited uses.</p>
                        <p>e. You acknowledge that the Website &amp; Mobile Application does not control the content or any information that may be posted by other users. Consequently, it is not responsible or liable for those contents or information.</p>

                        <p><strong><br/><br/>6. Intellectual Property Ownership</strong></p>
                        <p>a. You agree that we retain ownership of all Content included on the Website &amp; Mobile Application (text, graphics, video, software, data, page layout, images, and any other information capable of being stored in a computer) other than the contents uploaded by users.</p>
                        <p>b. You are granted a limited license only, subject to the restrictions provided in this Terms, nothing on this Website &amp; the Mobile Application shall be construed as granting any license or right to use any trademark or logo displayed on the Website &amp; Mobile Application without obtaining the prior written consent of the Owner.</p>
                        <p>c. You hereby agree not to reproduce or distribute the Owner’s intellectual property or use the intellectual property for any unlawful purpose.</p>

                        <p><strong><br/><br/>7. Your Content</strong></p>
                        <p>a. You undertake that You retain the exclusive right and ownership of Your Content and You are not infringing on any third party rights.</p>
                        <p>b. You retain all rights and ownership to Your Content. However, when You upload Your Content, You grant the Owner a worldwide license to communicate, distribute, host, make modification or derivative works (solely for the purpose of showcasing Your work), reproduce, publicly display, publicly perform and use such content. The license granted by You is for the purposes of marketing, promoting, and improving our services.</p>
                        <p>c. The Owner reserves the right to remove any of Your Content or any content that is unlawful, offensive, defamatory, or otherwise objectionable or violates any intellectual property rights or these Terms.</p>

                        <p><strong><br/><br/>8. Privacy Policy</strong></p>
                        <p>Our privacy policy explains how we treat Your personal data and protect Your privacy when You use our Service. By using our Service, You agree that the Owner can use such data in the manner described in the our Privacy Policy located in: <a href='https://transitonline.app/privacy-policy' target='_blank' rel="noreferrer">https://transitonline.app/privacy-policy</a>.</p>

                        <p><strong><br/><br/>9. Links to Third Party Sites/Services</strong></p>
                        <p>The Website &amp; Mobile Application may contain links to other websites. This Linked Sites are not under our control and You agree that we are not liable for the contents of any Linked Sites or link contained in any Linked Site.</p>

                        <p><strong><br/><br/>10. Electronic Communications</strong></p>
                        <p>You consent to receive electronic communications and You agree that all agreements, notices, disclosures and other communications we provide to You electronically, via email and on this Website &amp; the Mobile Application, satisfy any legal requirements that communications must be in writing.</p>

                        <p><strong><br/><br/>11. Reverse Engineering and Security</strong></p>
                        <p>You hereby agree as follows:</p>
                        <p>(I). not to reverse engineer or permit the reverse engineering or dissemble any code or software from or on the Website &amp; Mobile Application or Services<strong>.</strong></p>
                        <p>(II). not to violate the Security of the Website &amp; Mobile Application or other Services through any unauthorized access, circumvention of encryption or other security tools, data mining or interference with any host or User or network.</p>

                        <p><strong><br/><br/>12. Change to Service</strong></p>
                        <p>a. You accept that the Owner may vary, alter, amend or update the Content or Service, at any time and without Your consent.</p>
                        <p>b. You also agree that the may not be available at all times and this may be as a result of the maintenance or for any other reason and we shall not be held liable for the failure to provide this Service.</p>

                        <p><strong><br/><br/>13. Indemnification</strong></p>
                        <p>You hereby agree to indemnify the Owner, its employees, agents and third parties from and against all liabilities, cost, demands, cause of action, damages and expenses (including reasonable attorney’s fees) arising out of Your use or inability to use, any uploads made by You, Your violation of any rights of a third party and Your violation of applicable laws, rules or regulation.</p>

                        <p><strong><br/><br/>14. No Warranties</strong></p>
                        <p>You agree that You use this Website &amp; the Mobile Application solely at Your risk as the Owner does not warrant the accuracy of the contents in this Website &amp; the Mobile Application. You assume all the risk of viewing, reading, downloading the contents of this Website &amp; the Mobile Application.</p>
                        <p>The Owner expressly disclaims all express and implied warranties such as implied warranty of merchantability as the Owner makes no warranties that the Website &amp; Mobile Application or other Services will be accurate, error free, secure or uninterrupted.</p>
                        <p>The Owner makes no warranty about the suitability, reliability, availability, timeliness and accuracy of the information, Contents, and other materials contained herein for any purpose. The Owner hereby disclaims all warranties and conditions with regard to the information, software, Products and related graphics and materials, including all implied warranties or conditions of merchantability, fitness for a particular purpose, title and non-infringement.</p>
                        <p>You also agree that the Owner and its affiliates shall not be liable for any direct, indirect, punitive and all consequential damages or any damages whatsoever including, but not limited to damages for loss of use, data or profits, the failure to provide Services or for any information, software, Products, Services, related graphics and materials obtained through this Website &amp; the Mobile Application, or otherwise arising out of the use of this Website &amp; the Mobile Application, whether based on contract, negligence, strict liability, or otherwise.</p>

                        <p><strong><br/><br/>15. Service Interruptions</strong></p>
                        <p>The Owner may from time to time interrupt Your access or use of this Website &amp; the Mobile Application to perform some maintenance or emergency services and You agree that the Owner shall not be held liable for any damage, loss which may arise thereof.</p>

                        <p><strong><br/><br/>16. Termination/Restriction of Access</strong></p>
                        <p>The Owner reserves the right to, at its sole discretion, terminate Your access to this Website &amp; the Mobile Application and the related Service or any part thereof at any time, for any reason and without notice.</p>
                        <p>The Owner shall have the right to terminate or terminate/suspend Your account for violating the Terms of this Service.</p>
                        <p>If You register with us, You may terminate this Service at any time by issuing a prior notice to us. Once this is done, You will no longer be bound by the provisions of this Terms.</p>

                        <p><strong><br/><br/>17. General Provisions</strong></p>
                        <p>a. <strong>Assignment</strong>: The Owner shall be permitted to assign, transfer its rights and/or obligations under these Terms. However, You shall not be permitted to assign, transfer any rights and/or obligations under these Terms.</p>
                        <p>b.<strong> Entire Agreement</strong>: These Terms, disclaimers and any other agreement relating to the use of this Website &amp; the Mobile Application constitutes the entire agreement and shall supersede any other agreement.</p>
                        <p>c. <strong>Separate Agreements</strong>: You may have other legal agreements with us. Those agreements are separate from these Terms. These Terms are not intended to alter, amend, revise or replace the terms of the other agreement.</p>
                        <p>d. <strong>Applicable law</strong>: These Terms may be governed and construed in accordance with the Laws, regulations or guidelines of the Federal Republic of Nigeria and other treaties, or regulations which is applicable in Nigeria.</p>
                    </div>
                </div>
            </div>
         </div>
    )
}

export default Terms;