import React from 'react'
import MailchimpSubscribe from "react-mailchimp-subscribe"
import CustomForm from './CustomForm'

const Newsletter = ({newsletterDisplayObject}) => {
    const {heading, message} = newsletterDisplayObject

    return (
        <div id="newsletter"className="newsletter-container">
            <div className="container"> 
                <div className="newsletter-main-container">
                        <div className="newsletter-heading-container">
                        <h1 className="heading item">{heading}</h1>
                        <p className="item">{message}</p>
                        </div>
                        <MailchimpSubscribe
                            url={process.env.REACT_APP_MAILCHIMP_URL}
                            render={({ subscribe, status, message }) => (
                            <CustomForm
                                status={status}
                                message={message}
                                onValidated={(formData,nodes) =>{subscribe(formData);nodes.email.value=''; nodes.name.value='';}}
                           />
                        )}
                      />

                </div>
            </div>
        </div>
    
    )
}

export default Newsletter
