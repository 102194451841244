import React from 'react'
import { Link } from 'react-scroll'
import TransitImg from '../../assets/images/transit-bus.jpg'
// import TransitImg2 from '../../assets/images/transit-bus-rear.jpg'

const Intro = () => {
    return (
        <div className="intro-img-main" style={{color:'black'}}>
            <div className="container">
                <div className="intro-img-container">
                    <div className="intro-section">
                        <h3 className="intro-title">Enjoyable Mobility Should Become A <br className="hide-on-mobile"/>Basic Human Right</h3>
                        <p className="intro-subtitle">... and we are spearheading that change.</p>
                        {/* <p className="intro-subtitle">... and we are changing the Transit Experience.</p> */}
                        <p className="intro-content">
                            Transit is a tech-driven transportation service connecting passengers in Africa to premium, reliable, convenient, safer, predictable 
                            and affordable bus rides within cities.
                        </p>
                        <p className="intro-content">
                            We enable you to:
                        </p>
                        <p className="banner-sub-text intro-content">
                            <span><i className="fa fa-check-circle"></i></span> have a better commute experience <br/>
                            <span><i className="fa fa-check-circle"></i></span> save time, money and energy <br/>
                            <span><i className="fa fa-check-circle"></i></span> maximize your productivity on-the-go <br/>
                            <span><i className="fa fa-check-circle"></i></span> enjoy a richer life
                        </p>
                        <div className="intro-btn-container">
                            <div className="intro-btn-holder">
                                <Link to="newsletter" offset={-80} smooth={true} duration={1000}>< button  className="intro-btn"> Get Early Access</ button></Link>
                                <a href="https://tdwc6x40muc.typeform.com/to/dX6N641d" target="_blank" rel='noreferrer' className="intro-btn">Signup as Service Partner</a>
                            </div>
                        </div> 
                    </div>
                    <div className="intro-img-box">
                        {/* <img  className="intro-img bottom" src={TransitImg2} alt="transit-img"/> undo if alterante images are to be used - 
                        the classNames 'bottom' and 'top' are important to achieve this */}
                        <img  className="intro-img top" src={TransitImg} alt="transit-bus-transportation"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Intro
