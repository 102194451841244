import React, { useRef } from 'react';
import Carousel from 'react-elastic-carousel';
import StarRatings from 'react-star-ratings';
import '../../assets/css/Testimonial.css';
// import img1 from '../../assets/images/01.jpg';
// import img2 from '../../assets/images/02.jpg';
import jonah from '../../assets/images/jonah.jpeg';
const Testimonial = () => {
    const items = [
        // { name: 'Ayodeji Bankole', title: 'CEO - In  The Making', text: "Aenean sollicitudin, lorem quis bibendum auctor This is Photoshop's version  of Lorem Ipsum. Proin gravida nibh vel velit auctor aliquet. , nisi elit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris.", img: img1, rating: 4.5 },
        // { name: 'Taiwo Ayoola', title: 'CEO - In  The Making', text: "Aenean sollicitudin, lorem quis bibendum auctor This is Photoshop's version  of Lorem Ipsum. Proin gravida nibh vel velit auctor aliquet. , nisi elit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris.", img: img2, rating: 5 },
        { name: 'Jonah . O', title: 'Founder/CEO - Verdant DevCore', text: "This is a really interesting service offering. One borne out of the genuine desire to meet a prevailing societal need. I look forward to using the app once it's launched.", img: jonah, rating: 0 }
    ]
    const carouselRef = useRef(null);
    let resetTimeout;
    const itemsPerPage = 1;
    const totalPages = Math.ceil(items.length / itemsPerPage)
    return (
        <div className="carousel-overall">
            <div className="container">
                <Carousel
                    ref={carouselRef}
                    divsToShow={3}
                    showArrows={false}
                    autoPlaySpeed={2000}
                    enableAutoPlay={true}
                    onNextEnd={({ index }) => {
                        clearTimeout(resetTimeout);
                        if (index + 1 === totalPages) {
                            if(carouselRef.current && carouselRef.current!== null){
                                resetTimeout = setTimeout(() => {
                                    carouselRef.current.goTo(0)
                                }, 2000)
                            }
                            clearTimeout(resetTimeout);
                        }
                        clearTimeout(resetTimeout);
                    }}
                    itemsToShow={1}
                >
                    {items.map((item, index) => {
                        return (
                            <div key={index} className="testimonial-carousel">
                                <div className="testimonial-image-holder">
                                    <img className="testimonial-img" src={item.img} alt='testimonial' />
                                </div>
                                <StarRatings
                                    rating={item.rating}
                                    starRatedColor={'goldenrod'}
                                    starDimension="15px"
                                    starSpacing="0"
                                />
                                <div className="testimonial-stick-holder">
                                    <span className="one">|</span>
                                    <span className="two">|</span>
                                    <span className="three">|</span>
                                </div>
                                <p className="testimonial-text">{item.text}</p>
                                <div className="testimonial-author">
                                    <h3 className="testifier-title">{item.name}</h3>
                                    <p className="testifier-position">{item.title}</p>
                                </div>
                            </div>
                        )
                    })}
                </Carousel>
            </div>
        </div>
    )
};

export default Testimonial;