import React from 'react';
import '../../assets/css/Pricing.css';
import { Link } from 'react-scroll'

const Pricing = () => {
    return (

        <div className="pricing-container">
            <div className="container">
                <div className="pricing-main-container">
                    <div className="services-heading">
                        <h1 className="heading item">Our Service Types</h1>
                        <p className="item">
                            Elite bus sharing services to get you moving like a bougie, with treasured sanity 
                            and peace of mind — across multiple city locations.
                        </p>
                    </div>
                    
                    <div className="pricing-card-container item">
                        <div className="pricing-card card-1">
                            <div>
                                <h5 className="card-header">Transit Lite</h5>
                                <div className="pricing-price">for Individuals</div>
                            </div>
                            <div className="pricing-benefits">
                                <div>On-Demand Bus Sharing</div>
                                <div>Extended Route Management</div>
                                <div>Flexible Booking &amp; Boarding</div>
                                <div>Total Convenience</div>
                                <div>Quality Networking</div>
                            </div>
                            <Link to="newsletter" offset={-80} smooth={true} duration={1000}><button className="pricing-cta">Get Early Access</button></Link>
                        </div>
                        <div className="pricing-card card-2">
                            <div>
                                <h5 className="card-header">Transit Business</h5>
                                <div className="pricing-price">for Organizations</div>
                            </div>
                            <div className="pricing-benefits">
                                <div>Corporate Charter Services</div>
                                <div>Additional Employee Benefits</div>
                                <div>Effective Transport Management</div>
                                <div>Reduced Overhead Cost</div>
                                <div>Team Bonding</div>
                            </div>
                            <Link to="newsletter" offset={-80} smooth={true} duration={1000}><button className="pricing-cta">Get Early Access</button></Link>
                        </div>
                        <div className="pricing-card card-3">
                            <div>
                                <h5 className="card-header">Transit Tour</h5>
                                <div className="pricing-price">for Tour Groups</div>
                            </div>
                            <div className="pricing-benefits">
                                <div>Private Charter</div>
                                <div>Excellent Trip Navigation</div>
                                <div>Fun Rides</div>
                                <div>Client-friendly Service</div>
                                <div>Trip Savings</div>
                            </div>
                            <Link to="newsletter" offset={-80} smooth={true} duration={1000}><button className="pricing-cta">Get Early Access</button></Link>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default Pricing
