import SortInputFields from './SortInputFields';
import Newsletter from '../home/Newsletter';
import Downloads from '../home/Downloads';
import '../../assets/css/Contact.css';

const Contact = () => {
    const newsletterDisplayObject = {
        heading:'Join Our Wait List',
        message:'Sign up to get early access — Get great discounts on rides, and free gifts when you ride with us.'
    }
    return (
        <div className="contact-overall">
            <div>
                <div className="contact-heading-wrapper">
                    <div className="contact-title">
                        <h2>Contact us</h2>
                    </div>
                    <div className="contact-context">
                        <p>We'll be extremely delighted to hear from you</p>
                    </div>
                </div>
                <div className="contact-holder">
                    <div className="container">
                        <SortInputFields />
                    </div>
                </div>
                <div className="contact-newsletter-holder">
                    <Newsletter newsletterDisplayObject={newsletterDisplayObject}/>
                </div>
                <div className="contact-downloads-holder">
                    <Downloads />
                </div>

            </div>
         </div>
    )
}

export default Contact;