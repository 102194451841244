import '../../assets/css/Policy.css';

const CookiePolicy = () => {
    return (
        <div className="policy-overall">
            <div>
                <div className="policy-heading-wrapper">
                    <div className="policy-title">
                        <h2>Cookie Policy</h2>
                    </div>
                    <div className="policy-context">
                        <p>Cookie Policy for This Website</p>
                    </div>
                </div>
                <div className="policy-holder">
                    <div className="container">
                        <p><strong>1. WHAT ARE COOKIES</strong></p>
                        <p>Cookies are small text files stored on your device and contain data that can be accessed by the website that stores them on your device. When you visit our Website, we may collect information from you automatically through cookies or a similar technology. We use these cookies to save and remember your preferences.</p>

                        <p><strong><br/><br/>2. THE TYPES OF COOKIES WE USE</strong></p>
                        <p>The types of cookies we use are as follows:</p>
                        <p>(I) <strong>Technical cookies</strong>: These cookies are essential for the correct functioning of our Website and to provide the Service required to our users.</p>
                        <p>(II) <strong>Third party cookies</strong>: While using our Website, You may receive cookies from sites managed by third parties.</p>

                        <p><strong><br/><br/>3. THIRD PARTY COOKIES</strong></p>
                        <p>Third party analytical cookies may also be installed. Third party analytical cookies are used to detect information on user behaviour on our Website. This is placed in order to monitor the performance and improve the usability of this Website.</p>
                        <p>These third party cookies may be used to collect the following information:</p>
                        <p>Your IP Address, the date, time and frequency of your visits on our website;<br/>Your name.</p>

                        <p><strong><br/><br/>4. INFORMATION COLLECTED</strong></p>
                        <p>We may collect information about your computer or any other electronic device which you use to have access to our Website, such as your IP address and your log information.</p>
                        <p>We may also collect personal information about you which you have provided to us on our Website or information relating to your purchase.</p>

                        <p><strong><br/><br/>5. THE PURPOSE OF USING COOKIES</strong></p>
                        <p>This Website uses cookies for the following purposes:</p>
                        <p>(I)<strong> For the normal functioning of our website</strong>: We use cookies to remember your preferences or settings so we can provide you with a more personalized user experience.</p>
                        <p>(II) <strong>For analytics and Website performance</strong>: We use cookies and other identifiers to collect performance and data usage. We use cookies to measure the users’ use of the Website, the page visited and to measure and analyze the performance of our Website. For example, we use cookies to conduct a research and improve our content, services we offer on the Website.</p>
                        <p>(III) <strong>To provide a personalized user experience</strong>: We use cookies to personalize your experience by recognizing you when you use our Website, keeping track of your preferences and providing you with content in line with your interests.</p>

                        <p><strong><br/><br/>6. CONSENT TO THE USE OF COOKIES</strong></p>
                        <p>We require your consent in order to use cookies on this Website.</p>
                        <p>By continuing to use our Website and the service we provide, it will be deemed that you have given the consent to the use of cookies.</p>

                        <p><strong><br/><br/>7. HOW TO OPT-OUT OF THE USE OF COOKIES</strong></p>
                        <p>You can opt-out of the use of cookies in the following manner:</p>
                        <p>By not clicking the ACCEPT button on the Cookies widget and leaving the web page.</p>
                        <p>However, deleting cookies from your browser may remove the preferences You have set for this Website.</p>
                    </div>
                </div>
            </div>
         </div>
    )
}

export default CookiePolicy;