import React, {useState, useRef, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import logo1 from '../../assets/images/logo.png'
import '../../assets/css/Navigation.css'
import {GiHamburgerMenu} from 'react-icons/gi'
import {IoCloseSharp} from 'react-icons/io5'
import {IoMoonOutline} from 'react-icons/io5';
import {MdWbSunny} from 'react-icons/md'
import {animateScroll as scroll} from 'react-scroll'
import { Link as LinkScroll } from 'react-scroll'

const Navigation = ({homeRef,offsetY, setChangeBg}) => {

    const navMenu = useRef(null)
    const [windowSize, setwindowSize] = useState(window.innerWidth)  
    const [themeMode, setThemeMode] = useState(false)


    const handleToggle = () => {

        setThemeMode(!themeMode);

        let root = document.documentElement

        if(themeMode === false){
            setChangeBg(true)
            root.style.setProperty('--textWhite', 'rgba(255,255,255,.6)')
            root.style.setProperty('--bgWhite', '#121212')
            root.style.setProperty('--newsletterBgWhite', 'rgba(56, 56, 56,1)')
            root.style.setProperty('--shadow', 'rgba(255,255,255,.6)')
            root.style.setProperty('--colorPrimary', 'rgba(56, 56, 56,1)')
            root.style.setProperty('--downloadColorPrimary', '#121212')
            root.style.setProperty('--hoverPrimary', '#333333')
            root.style.setProperty('--colorSecondary', 'rgba(255,255,255,.6)')
            root.style.setProperty('--colorBlack', 'rgba(255,255,255,.6)')
            root.style.setProperty('--colorlightGrey', '#383838')
            root.style.setProperty('--newsletterColorlightGrey', 'rgba(56, 56, 56,1)')
            root.style.setProperty('--bgFooterBlue', '#121212')
            root.style.setProperty('--screenshotGrey','#121212')
            root.style.setProperty('--screenshotGreyActive','#b5bab6')
            root.style.setProperty('--colorError', '#ff0000')
        }else{
            setChangeBg(false)
            root.style.setProperty('--textWhite', '#fff')
            root.style.setProperty('--bgWhite', '#fff')
            root.style.setProperty('--newsletterBgWhite', '#fff')
            root.style.setProperty('--shadow', 'rgba(0, 0, 0, .1)')
            root.style.setProperty('--colorPrimary', '#29b9f2')
            root.style.setProperty('--downloadColorPrimary', '#29b9f2')
            root.style.setProperty('--hoverPrimary', '#29b9f2')
            root.style.setProperty('--colorSecondary', '#a4a4a4')
            root.style.setProperty('--colorBlack', '#000000b3')
            root.style.setProperty('--colorlightGrey', '#F1F1F1')
            root.style.setProperty('--newsletterColorlightGrey', '#F1F1F1')
            root.style.setProperty('--bgFooterBlue', '#002255')
            root.style.setProperty('--bgWhite', 'white')
            root.style.setProperty('--textColor', '#ffffff')
            root.style.setProperty('--screenshotGrey','#b5bab6')
            root.style.setProperty('--screenshotGreyActive','#515252')
            root.style.setProperty('--colorError', '#860c0c')
        }
    }


    useEffect(()=>{
        window.addEventListener('resize', ()=> setwindowSize(window.innerWidth))

        if(window.innerWidth > 992){
            if(homeRef.current){            
                homeRef.current.style.display = `block`
            }

            navMenu.current.style.top='-100%';
        }
    },[windowSize, homeRef, themeMode])

    
    const showMenu = ()=>{

        navMenu.current.style.display = 'flex';
        navMenu.current.style.top='0';
        
        if(homeRef.current){
            homeRef.current.style.display='none'    
        }
    }

    const closeMenu =()=>{
        scroll.scrollToTop()
        navMenu.current.style.top='-100%';
        if(homeRef.current)
        homeRef.current.style.display='block';
    }

    return (
        <nav className={offsetY >= 252 ? "navigation-fixed": "navigation"}>
            <div className="container">
            <div className="nav-container">
                <div className="nav-logo"><NavLink onClick={closeMenu} to="/"><img src={logo1} alt="brand-logo" /></NavLink></div>
                <div onClick={showMenu} className="nav-openMenu"><GiHamburgerMenu className="open-icon" /></div>
                <ul ref={navMenu} className="nav-list">
                    <li className="nav-item"><NavLink onClick={closeMenu} to="/">Home</NavLink></li>
                    <li className="nav-item"><NavLink onClick={closeMenu} to="/how-it-works">How It Works</NavLink></li>
                    <li className="nav-item"><NavLink onClick={closeMenu} to="/about">About</NavLink></li>
                    {/* <li className="nav-item"><NavLink onClick={closeMenu} to="/#">Partnerships</NavLink></li> */}
                    {/* <li className="nav-item"><a onClick={closeMenu} href={`${process.env.REACT_APP_PARTNER_SIGNUP_URL_2}`} target="_blank" rel='noreferrer'>Partnerships</a></li> */}
                    <li className="nav-item"><a onClick={closeMenu} href={`${process.env.REACT_APP_PARTNER_SIGNUP_URL_1}`} target="_blank" rel='noreferrer'>Partnerships</a></li>
                    <li className="nav-item"><NavLink onClick={closeMenu} to="/contact">Contact</NavLink></li>
                    <li className="nav-item btn-signup"><LinkScroll smooth={true} duration={1000} offset={-80} onClick={closeMenu} to="newsletter">Get Early Access</LinkScroll></li>
                    <div onClick={closeMenu} className="nav-closeMenu"><IoCloseSharp className="close-icon"  /></div>
                    <div className="theme-toggle">
                        <button><IoMoonOutline className={themeMode? "hideButton moon" : "moon"} onClick={handleToggle} /></button>
                        <button><MdWbSunny className={themeMode? "sun" : "hideButton sun"} onClick={handleToggle} /></button>
                    </div>        
                </ul>
            </div>
            </div>
            <div className="theme-toggle outside-menu">
                <button><IoMoonOutline className={themeMode? "hideButton moon" : "moon"} onClick={handleToggle} /></button>
                <button><MdWbSunny className={themeMode? "sun" : "hideButton sun"} onClick={handleToggle} /></button>
            </div>
        </nav>
        )
}

export default Navigation
