import React from 'react';
import CountUp from 'react-countup';
import '../../assets/css/Milestone.css';

const Milestone = () => {
    return (
        <div className="milestone-overall">
            <div className="container">
                <div className="milestone-holder">
                    <div className="milestone-context">
                        <div className="main context-one">
                            <h3 id="digitOne">
                                {/* <CountUp end={0} duration={7} /> */}
                            <span className="coming-soon">Coming soon...</span>
                            </h3>
                            <p>App Downloads</p>
                        </div>
                        <p className="divider-one"></p>
                        <div className="main context-two">
                            <h3 id="digitTwo">
                            {/* <CountUp end={0} duration={7} /> */}
                            <span className="coming-soon">Coming soon...</span>
                            </h3>
                            <p>Active Users</p>
                        </div>
                        <p className="divider-two"></p>
                        <div className="main context-three">
                            <h3 id="digitThree">
                            {/* <CountUp end={0}  duration={7} /> */}
                            <span className="coming-soon">Coming soon...</span>
                            </h3>
                            <p>Rides Completed</p>
                        </div>
                        <p className="divider-three"></p>
                        <div className="main context-four">
                            <h3>
                            <CountUp end={26} duration={7} />
                            </h3>
                            <p>Transit Routes</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Milestone;