import React from 'react'
import ReactPlayer from 'react-player'
import { Link } from 'react-scroll'
import intro_video from '../../assets/videos/transit.mp4';

const HowToVideoSection = () => {
    return (
        <div className="howto-vid-main" style={{color:'black'}}>
            <div className="container">
                <div className="howto-vid-container">
                    <div>
                        <ReactPlayer controls={true} width={window.innerWidth < 990?"100%":"80%"} url={intro_video}/>
                    </div>
                    <div>
                        <p className="howto-content">
                            Picture trying to use the traditional public bus transport to go to work, run an errand, meetup with 
                            acquaintances, visit a place, or attend an interview/appointment, without knowing <b>IF</b> and <b>WHEN</b> a 
                            bus will arrive your bus station.
                        </p>
                        <p className="howto-content">If eventually a bus arrives, you most likely would still have the 
                            following uncertainties to deal with on your commute: gross inconvenience, time-wasting and unsolicited stops, 
                            erratic drivers &amp; bus assistants, driver recklessness, insecurity of life &amp; personal 
                            property (while on-board), and poor route management.
                        </p>
                        <p className="howto-content">
                            Alternatively, if you opt to use a private cab service to lower the probability of facing these 
                            uncertainties, you most certainly will be committing yourself to spending way more than you actually and 
                            sensibly should.
                        </p>
                        <p className="howto-content">
                            Transit eliminates all of these uncertainties and more while letting you spend considerably for a 
                            premium commute experience. You'll only need two taps on the app and you can book a seat on Transit.  
                        </p>
                        <div className="howto-btn-container">
                            <div className="howto-btn-holder">
                                <Link to="newsletter" offset={-80} smooth={true} duration={1000}>< button  className="howto-btn">Get Early Access</ button></Link>
                                <a href="https://tdwc6x40muc.typeform.com/to/dX6N641d" target="_blank" rel='noreferrer' className="howto-btn">Signup as Service Partner</a>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HowToVideoSection
