import React from 'react';
import '../../assets/css/Downloads.css';
import { Link as LinkScroll } from 'react-scroll'
import {FaInfoCircle} from 'react-icons/fa'

const Download =()=>{
    return(
        <div id="downloads" className="download-overall">
            <div className="container">
                <div className="download-heading-wrapper">
                    <div className="download-title">
                        <h1>Get Moving With Transit</h1>
                    </div>
                    <div className="download-context">
                        <p>We provide you the most efficient, convenient, safe, timely, productive, and hassle-free public transportation service. Begin by downloading our app.</p>
                    </div>
                </div>
                <div className="downoad-btn-container">
                    <div className="download-btn-holder">
                        < button href="!#" className="download-btn"><i className="fa fa-apple"/>App store</ button>
                        <button href="!#" className="download-btn"><i className="fa fa-android"></i>Play store</button>
                    </div>
                </div>
                <div className="download-heading-wrapper-btm">
                    <div className="download-info">
                    <p><FaInfoCircle className="info-icon" />We're currently still building our app, <LinkScroll smooth={true} duration={1000} offset={-80} to="newsletter">join our wait list</LinkScroll> and you'll be first to hear about our launch plus win great gifts.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Download;