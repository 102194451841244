import Typewriter from 'typewriter-effect/dist/core';
import React, { useEffect, useRef } from 'react';
import '../../assets/css/Banner.css';
import { Link } from 'react-scroll'

// import inner_one from '../../assets/images/inner-1.png';
import banner_screenshot from '../../assets/images/screenshot-04.png'
import path_one from '../../assets/images/path_one.png';
import path_two from '../../assets/images/path_two.png';
import path_three from '../../assets/images/path_three.png';
import intro_video from '../../assets/videos/transit.mp4';
import PhoneInputField from './PhoneInputField';
import bannerBg from '../../assets/images/banner-bg-image.png';

const Banner = ({offsetY, changeBg}) => {
    const bannerRef = useRef(null)
    const headerRef = useRef(null)
     
    useEffect(()=>{
        var typewriter = new Typewriter(headerRef.current, {
            loop: true,
            delay: 75,
            deleteSpeed:3
          });
        typewriter
        .typeString('Safety, Elegance, Convenience, and Productivity matter the most.')
        .deleteAll()
        .pauseFor(500)
        .typeString('Rewriting the African Everyday Commute Story is worth doing,')
        .pauseFor(500)
        .typeString(' and doing well.')
        .deleteAll()
        .pauseFor(500)
        .typeString('Everyone deserves a true <b>Transit Experience</b>')
        .pauseFor(500)
        .deleteAll()
        .typeString(" that'll eventually become a huge part of their lives")
        // .pauseFor(500)
        .deleteAll()
        .typeString(" — one ride a time.")
        .pauseFor(500)
        .deleteAll()
        .typeString("<b>Commute with Pride</b> & <b>Ease!</b>")
        .pauseFor(1500)
        .start()

        if(changeBg){
            bannerRef.current.style.backgroundImage = 'none'
            bannerRef.current.style.backgroundColor = "#383838"
        } else{
            bannerRef.current.style.backgroundImage = `url(${bannerBg})`
            bannerRef.current.style.backgroundColor = 'transparent'
        }
    })

    return (
        <>
            {/* <!-- banner Slider start --> */}
            <div ref={bannerRef} className="banner-main ">
                <div className="container">
                    <div className="banner-content-container">
                        <div className="banner-left-content">
                            <div>
                                <h1><b>Optimizing Shared Mobility Within African Cities</b></h1>
                                {/* <h1>
                                    <span ref={headerRef}></span>
                                </h1> */}
                                <p className="banner-caption">
                                    <b>Tech-driven, user-centric daily transportation service enabling productivity, efficiency and personalized commute experience.</b>
                                </p>
                                <p className="banner-sub-text">
                                    Affordable <span><i className="fa fa-check-circle"></i></span> &nbsp; 
                                    Super Convenient <span><i className="fa fa-check-circle"></i></span> &nbsp;
                                    Easy to Use <span><i className="fa fa-check-circle"></i></span> &nbsp;
                                    Safe <span><i className="fa fa-check-circle"></i></span> &nbsp;
                                </p>
                                <div className="phoneHolder">
                                    <PhoneInputField />
                                </div>
                                <div>
                                    <Link to="newsletter" offset={-80} smooth={true} duration={1000}><button className="join-waitlist-cta">Get Early Access</button></Link>
                                    <a href={`${process.env.REACT_APP_PARTNER_SIGNUP_URL_1}`} target="_blank" rel='noreferrer' className="partner-cta">Signup as a Partner</a>
                                    {/* <a href={`${process.env.REACT_APP_PARTNER_SIGNUP_URL_2}`} target="_blank" rel='noreferrer' className="partner-cta">Signup as Partner</a> */}
                                </div>
                                {/* <div className="slider-video-button">
                                    <div className="video-popup">
                                        <a href={intro_video} className="popup-youtube video-link">
                                            <div className="video-content">
                                                <div className="ht-popup-video video-button">
                                                    <div className="video-mark">
                                                        <div className="wave-pulse wave-pulse-1"></div>
                                                        <div className="wave-pulse wave-pulse-2"></div>
                                                    </div>
                                                    <div className="video-button__two">
                                                        <div className="video-play">
                                                            <span className="video-play-icon"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div> */}
                            </div>
                        </div>

                        <div className="banner-right-content slider-inner-imge-2 banner-right">
                            <img src={banner_screenshot} alt="mobile-phone" />
                        </div>
                    </div>

                    <div className="parallax-img one"
                        style={{
                            transform: offsetY > 0 ? `translate(-10px,-${50}px)` : `translate(5px,${0}px)`,
                            transition: "transform ease-in-out 1.5s"
                        }}
                        data-parallax='{"x": -10, "y": -250}'><img src={path_one} alt="" /></div>

                    <div className="parallax-img two"
                        style={{
                            transform: offsetY > 0 ? `translate(-10px,${50}px)` : `translate(5px,${0}px)`,
                            transition: "transform ease-in-out 1.5s"
                        }}
                        data-parallax='{"x": 0, "y": 250}'><img src={path_two} alt="" /></div>

                    <div className="parallax-img three"
                        style={{
                            transform: offsetY > 0 ? `translate(-10px,-${50}px)` : `translate(5px,${0}px)`,
                            transition: "transform ease-in-out 1.5s"
                        }}
                        data-parallax='{"x": 30, "y": -250}'><img src={path_three} alt="" /></div>


                </div>
            </div>
            {/* <!-- banner Slider end --> */}

        </>
    )
}

export default Banner;
