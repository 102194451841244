import React from 'react'
import '../../assets/css/Footer.css'
import logo from '../../assets/images/logo.png'
import {FaFacebookF,FaHeart,FaInstagram,FaLinkedinIn,FaTwitter} from 'react-icons/fa'
import {NavLink} from 'react-router-dom';

const Footer = () => {
    const date = new Date();
    const year = date.getFullYear();

    return (
        <div className="footer">
            <div className="container">
                <div className="footer-main-container">
                        <div className="footer-top">
                            <div className="footer-row-1">
                                <div className="footer-row-1-item-1">
                                    <h3>Product</h3>
                                    {/* <div><a href="#">Features</a></div>  */}
                                    <div><a href="/how-it-works">How It Works</a></div>
                                    {/* <div><a href={`${process.env.REACT_APP_PARTNER_SIGNUP_URL_2}`} target="_blank" rel='noreferrer'>Partnerships</a></div> */}
                                    <div><a href={`${process.env.REACT_APP_PARTNER_SIGNUP_URL_1}`} target="_blank" rel='noreferrer'>Partnerships</a></div>
                                    <div><a href="#">RoadMap (Coming Soon)</a></div>
                                    <div><a href="#">Feature Requests (Coming Soon)</a></div>
                                </div>

                                <div className="footer-row-1-item-2">
                                    <h3>Company</h3> 
                                    <div><a href="/">Home</a></div>
                                    <div><a href="/about">About</a></div>
                                    <div><a href={`${process.env.REACT_APP_PARTNER_SIGNUP_URL_1}`} target="_blank" rel='noreferrer'>Partnerships</a></div>
                                    {/* <div><a href="#">Careers</a></div> */}
                                </div>

                                <div className="footer-row-1-item-3">
                                    <h3>Resources</h3> 
                                    <div><a href="#">FAQs (Coming Soon)</a></div>
                                    <div><a href="#">Blog (Coming Soon)</a></div>
                                    <div><a href="#">Press Kit (Coming Soon)</a></div>
                                    {/* <div><a href="#">Testimonials</a></div> */}
                                </div>

                                <div className="footer-row-1-item-4">
                                    <h3>Legal</h3> 
                                    <div><a href="/privacy-policy" target='_blank'>Privacy Policy</a></div>
                                    <div><a href="/terms" target='_blank'>Terms of Use</a></div>
                                    <div><a href="/disclaimer">Disclaimer</a></div>
                                    <div><a href="/cookie-policy" target='_blank'>Cookie Policy</a></div>
                                </div>
                            </div>
                            <div className="footer-row-2">
                                <div className="footer-row-2-item-1">
                                    <h3>Support</h3> 
                                    <div><a href="/contact">Contact Us</a></div>
                                    <div className="footer-text">Hours:<br/> Monday - Friday<br />9am - 5pm</div>
                                    <div><a href={`mailto:${process.env.REACT_APP_EMAIL}?subject=New Email from Website`}>{process.env.REACT_APP_EMAIL}</a></div>
                                    <div><a className="footer-tel" href={`tel:${process.env.REACT_APP_PHONE}`}>{process.env.REACT_APP_PHONE}</a></div>
                                    {/* <div><a href="/contact">Visit our Help Desk</a></div> */}
                                </div>
                                <div className="footer-row-2-item-2 footer-text">
                                    <h3>Trusted Security</h3> 
                                    <div>GDPR Compliant | We will never share your data.
                                    </div>
                                    {/* <div>GDPR Compliant</div> */}
                                </div>
                            </div>
                    </div>
                    <div className="footer-bottom">
                        <div className="top-div footer-text">
                            <div className="left-of-top">
                            <NavLink to="/"><img src={logo} alt="company logo"/></NavLink>
                                <div><div>{process.env.REACT_APP_ADDRESS}</div></div>
                            </div>
                            <div className="right-of-top">
                                <a href={process.env.REACT_APP_TWITTER_PROFILE} target="_blank" rel="noreferrer"><FaTwitter className="footer-icon" /></a>
                                <a href={process.env.REACT_APP_FACEBOOK_PROFILE} target="_blank" rel="noreferrer"><FaFacebookF className="footer-icon" /></a>
                                <a href={process.env.REACT_APP_LINKEDIN_PROFILE} target="_blank" rel="noreferrer"><FaLinkedinIn className="footer-icon" /></a>
                                <a href={process.env.REACT_APP_INSTAGRAM_PROFILE} target="_blank" rel="noreferrer"><FaInstagram className="footer-icon" /></a>
                            </div>
                        </div>

                        <div className="bottom-div">
                            <div className="left-of-bottom">
                                <div>&#169; {year} Transit | All rights reserved</div>
                                <div>Made with <FaHeart className="footer-icon" /></div>  
                            </div>
                            <div className="right-of-bottom footer-text">Transit-Tech Limited</div>
                        </div>      
                    </div>
            </div>
        </div>
        </div>
    )
}

export default Footer
