import React, {useState, useEffect} from 'react';
import {useWindowScroll} from 'react-use';
import '../../assets/css/ScrollToTop.css';

const ScrollToTop = () => {
    const {y: pageYOffset} = useWindowScroll();
    const [visible, setVisibility] = useState(false);

    useEffect( () => {
        if(pageYOffset > 300) {
            setVisibility(true);
        } else {
            setVisibility(false);
        }
    },[pageYOffset]);

    const goToTop = () => {
        window.scrollTo({top: 0, behavior: "smooth"});
    }

    if(!visible) {
        return false;
    }

    return(
        <div className="feature-scrolltotop" onClick={goToTop}>
            <i className="fa fa-chevron-up"></i>
        </div>
    )
}
export default ScrollToTop;