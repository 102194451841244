import React from 'react'
import { withRouter } from 'react-router'
import Downloads from '../home/Downloads'
import Newsletter from '../home/Newsletter'
import HowToVideoSection from './HowToVideoSection'
import MainBanner from './MainBanner'
import Steps from './Steps'


const HowItWorks = ({ changeBg }) => {
    const newsletterDisplayObject = {
        heading:'Join Our Wait List',
        message:'Sign up to get early access — Get great discounts on rides, and free gifts when you ride with us.'
    }



    return (
        <div className="how-it-works-container" >
            <MainBanner />
            <HowToVideoSection />
            <Steps changeBg={changeBg} />
            <Newsletter newsletterDisplayObject={newsletterDisplayObject} />
            <div className="howItWorksDownload">
                <Downloads />
            </div>
        </div>
    )
}

export default withRouter(HowItWorks)
