import React from "react";
import 'react-phone-input-2/lib/style.css';
import '../../assets/css/PhoneInputField.css';
import PhoneInput from 'react-phone-input-2';
import startsWith from 'lodash.startswith';

export default class MyForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: "",
      phoneNumber: "",
      serviceErrorMessage: ""
    };
  }


  render() {
    const {status, phoneNumber, serviceErrorMessage} = this.state;

    const inputStyle = {
      borderRadius: 0,
    }
    return (
      <form
        onSubmit={this.submitForm}
        action={process.env.REACT_APP_PHONE_FORM_URL}
        method="POST"
      >
        <div className="feature-phone-overall">
          <div className="feature-phone-wrapper">
            <PhoneInput
              containerClass={'phone-input-container'}
              inputStyle={inputStyle}
              country={'ng'}
              placeholder={'234 xxx xxx xxx x'}
              value={phoneNumber}
              inputProps={{ maxLength: '17', name: "phoneNumber", type: "text" }}
              onChange={phoneNumber => this.setState({ phoneNumber: phoneNumber, status: '' })}
              isValid={(inputNumber, country, countries) => {
                return countries.some((country) => {
                  return startsWith(inputNumber, country.dialCode) || startsWith(country.dialCode, inputNumber);
                });
              }}
            />
            <div className="phone-input-button">
              {status === "SUCCESS" ?
                <div className="phone-input-button">
                  <button disabled>
                    <i className="successText fa fa-check-circle"></i>
                  </button>
                </div> 
                :
                <div className="phone-input-button">
                  <button disabled = {
                    phoneNumber !== undefined ?
                      phoneNumber[0] === 0 ? true : false
                    : false
                  }>
                    <i className="fa fa-arrow-right"></i>
                  </button>
                </div>
              }
            </div>
          </div>
          {/* <span className="phone-subscribe">Add phone number to receive service updates from us.</span> */}
          <span className="phone-subscribe">Add your phone number to join our wait list.</span>
        </div>
        {status === "SUBMITTING" ?
          <p className="submit-message">Sending...</p>: null
        }
        {status === "FAIL" ? 
          <p className="fail-message">Network Error: Please check your connection and try again.</p> : null
        }
        {status === "SUCCESS" ?
          <p className="success-message">Phone number successfully added!</p>: null
        }
        {phoneNumber[0] === '0'|| status === "INVALID" ?
          <p className="fail-message">Invalid phone input: Please enter correct phone number.</p> : null
        }
        {status === "ERROR" ? 
          <p className="fail-message">{serviceErrorMessage}</p> : null
        }
      </form>

    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    if (this.state.phoneNumber[0] !== '0' && (this.state.phoneNumber.length > 9 && 
      this.state.phoneNumber.length <= 13)) {
        this.setState({ status: "SUBMITTING" });
        xhr.setRequestHeader("Accept", "application/json");
        this.setState({ status: "SUBMITTING" });
        xhr.onreadystatechange = () => {
          if (xhr.readyState !== XMLHttpRequest.DONE) return;
          if (xhr.status === 200) {
            form.reset();
            this.setState({ status: "SUCCESS", phoneNumber: '' });
          } else if(xhr.responseText) {
            this.setState({status: "ERROR", serviceErrorMessage: xhr.responseText});
          } else {
            this.setState({status: "FAIL"})
          }
        };
        xhr.send(data);
    } else {
      this.setState({ status: "INVALID" });
      return;
    }
  }
}