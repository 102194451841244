import React from 'react'
import '../../assets/css/About.css'
import Downloads from '../home/Downloads'
import Newsletter from '../home/Newsletter'
import Steps from '../how-it-works/Steps'
import Intro from './Intro'
import MainHeading from './MainHeading'
import VisionAndMission from './VisionAndMission'

const About = ({changeBg}) => {
    const newsletterDisplayObject = {
        heading:'Join Our Wait List',
        message:'Sign up to get early access — Get great discounts on rides, and free gifts when you ride with us.'
    }

    return (
        <div className ="about-section">
            <MainHeading />
            <Intro />
            <VisionAndMission changeBg={changeBg} />
            <Newsletter newsletterDisplayObject={newsletterDisplayObject}/>
            <Steps changeBg={changeBg}/>
            <div className="about-download">
                <Downloads />
            </div>
        </div>
    )
}

export default About
