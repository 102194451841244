import React, { useEffect, useRef } from 'react';
import '../../assets/css/HowItWorks.css'
import '../../assets/css/Feature.css';
import cloudDownloadImg from '../../assets/icons/cloud-download.svg';
import checkValidate from '../../assets/icons/check-calidate.svg';
import busImage from '../../assets/icons/bus.svg';


const Steps=({changeBg})=>{
  const stepsRef = useRef(null)
  useEffect(()=>{
      if(changeBg){
          stepsRef.current.style.background = "#383838"
      } else{
          stepsRef.current.style.background = `#f1f1f1`
      }
  })

  return(
    <div ref={stepsRef} className="steps-overall">
      <div className='container'>
        <div className="feature-heading">
          <h1 className="feature-title">Transit Better in 3 Easy Steps</h1>
          <p className="feature-text">Less worries, zero hassles, efficient commute time, unrivaled convenience, accelerated productivity — all on our app.</p>
        </div>
        <div className="feature-idea-container">
        <div className="feature-idea-box">
          <div className="image-holder">
            <img className="image-resize" src={cloudDownloadImg} alt="one" />
          </div>
          <div className="context">
            <h3>Download the App</h3>
            <p>Get the mobile iOS and Android app and set up your account.</p>
          </div>
        </div>
        <div className="feature-idea-box">
          <div className="image-holder">
            <img className="image-resize" src={checkValidate} alt="two" />
          </div>
          <div className="context">
            <h3>Book A Ride</h3>
            <p>Request a bus ride now or schedule one for later.</p>
          </div>
        </div>
        <div className="feature-idea-box">
          <div className="image-holder">
            <img className="image-resize" src={busImage} alt="three" />
          </div>
          <div className="context">
            <h3>Transit Elegantly</h3>
            <p>Confirm your request, track your vehicle arrival and start your trip.</p>
          </div>
        </div>

      </div>
      </div>
    </div>
  );
}

export default Steps;