import React, { useState, useEffect } from 'react';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import startsWith from 'lodash.startswith';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import Recaptcha from 'react-recaptcha';

const SortInputFields = () => {
    const [isVerified, setIsVerified] = useState(false);
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneError, setPhoneError] = useState(false);
    const [subject, setSubject] = useState('');
    const [subjectError, setSubjectError] = useState(false);
    const [message, setMessage] = useState('');
    const [messageError, setMessageError] = useState(false);
    const [hidden, setHidden] = useState(false);
    const [status, setStatus] = useState('');
    const [serviceErrorMessage, setServiceErrorMessage] = useState('');
    const [timeRecaptcha, setTimeRecaptcha] = useState(false);

    // Function to delay the execution of the reCAPTCHA component
    // to ensure reCAPTCHA script is completely loaded.
    const delayWidget = () => {
        setTimeout(setTimeRecaptcha(true), 5000)
        return timeRecaptcha;
    };
    useEffect(() => {
        return () => delayWidget();
    }, [timeRecaptcha]);


    // Function to check if there is phone number entry
    const isPhoneNumberEntered = (phoneNumber) => {
        if (phoneNumber) {
            return true;
        } else {
            return false;
        }
    }

    // Function to check phone number length validity
    const isPhoneNumberLengthValid = (phoneNumber) => {
        if (isPhoneNumberEntered(phoneNumber)) {
            if (phoneNumber.length > 9 && phoneNumber.length <= 13) {
                return true;
            } else {
                return false;
            }
        }
    }

    // Google reCAPTCHA onLoad callback function
    const onloadCallback = () => {
        return true
    }

    // Google reCAPTCHA onVerify callback function
    const verifyCallback = (res) => {
        res ? setIsVerified(true) : setIsVerified(false);
    }

    // Function to sumit contact form
    const handleSubmit = (event) => {
        event.preventDefault();
        if ((name !== '' && !nameError) &&
            (email !== '' && !emailError) &&
            (subject !== '' && !subjectError) &&
            (message !== '' && !messageError)) {
            // Now checks for a phone number entry after validating other form input fields
            if (isPhoneNumberEntered(phoneNumber)) {
                if (isPhoneNumberLengthValid(phoneNumber) !== true || phoneError) {
                    setStatus("INVALID");
                    return;
                }
            }
            // Get Form Data to Send
            const form = event.target;
            const data = new FormData(form);
            const xhr = new XMLHttpRequest();
            xhr.open(form.method, form.action);
            xhr.setRequestHeader("Accept", "application/json");
            // Check reCAPTCHA Verification
            if (isVerified) {
                // When verified, set form status to 'Submitting'
                setStatus('SUBMITTING');

                // Condition for setting submit button display
                setHidden(true);
                xhr.onreadystatechange = () => {
                    if (xhr.readyState !== XMLHttpRequest.DONE) return;
                    if (xhr.status === 200) {
                        form.reset();
                        setStatus('SUCCESS');
                        setName('');
                        setMessage('');
                        setPhoneNumber('');
                        setSubject('');
                        setEmail('');
                        setHidden(false);
                    } else {
                        if (xhr.responseText) {
                            setServiceErrorMessage(xhr.responseText);
                            setStatus('ERROR');
                        } else {
                            setStatus('FAIL');
                        }
                        setHidden(false);
                    }
                };
                xhr.send(data);
                setTimeRecaptcha(false);
            } else {
                setStatus('NOT_VERIFIED');
                setHidden(false);
            }
        } else {
            setStatus('EMPTY');
        }
    }

    // Input Fields Validation Check
    const validateInput = (data) => {
        let nameFormat = /^([A-Za-z]+?)\s([A-Za-z]+?)$/                 // for single name /^[A-Za-z]+$/;
        let emailFormat = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/;
        let phoneFormat = /^\d+$/;
        switch (data.type) {
            case 'name':
                setStatus('');
                const firstname = data.value.split(' ')[0];
                const lastname = data.value.split(' ')[1];

                if (firstname?.length > 1 && lastname?.length > 1) {
                    data.value.match(nameFormat) ? setNameError(false) : setNameError(true);
                } else { setNameError(true) }
                break;
            case 'email':
                setStatus('');
                data.value.match(emailFormat) ? setEmailError(false) : setEmailError(true);
                break;
            case 'phone':
                setStatus('');
                if (data) {
                    data.value.match(phoneFormat) && isPhoneNumberLengthValid(phoneNumber) ? setPhoneError(false) : setPhoneError(true);
                }
                break;
            case 'subject':
                setStatus('');
                data.value.trim().length >= 5 ? setSubjectError(false) : setSubjectError(true);
                break;
            case 'message':
                setStatus('');
                data.value.trim().length >= 10 ? setMessageError(false) : setMessageError(true);
                break;
            default:
                return;
        }
    }

    return (
        <div className="content">
            <div className="contactInfo">
                <div className="contact-title-info">
                    <h3>Contact Info</h3>
                    <p>We love to receive your feedback, build strong &amp; valuable partnerships, and answer some of the questions you may have about our service.</p>
                    <p>Please fill the form and we'll be in touch with you shortly.</p>
                </div>
                <div className="contactInfoBx">
                    <div className="box">
                        <div className="text">
                            <i className="fa fa-phone"></i>
                            <span className="addressLink"><a href={`tel:${process.env.REACT_APP_PHONE}`}>{process.env.REACT_APP_PHONE}</a></span>
                        </div>
                    </div>
                    <div className="box">
                        <div className="text">
                            <i className="fa fa-envelope-o"></i>
                            <span className="addressLink">
                                <a href={`mailto:${process.env.REACT_APP_EMAIL}?subject=New Email from Website`}>{process.env.REACT_APP_EMAIL}</a>
                            </span>
                        </div>
                    </div>
                    <div className="box">
                        <div className="text">
                            <i className="fa fa-map-o"></i>
                            <span className="addressLink">{process.env.REACT_APP_ADDRESS}</span>
                        </div>
                    </div>

                    <div className="box">
                        <div className="social-handles">
                            <div className="link"><a href={process.env.REACT_APP_TWITTER_PROFILE} target="_blank" rel="noreferrer"><FaTwitter /></a></div>
                            <div className="link"><a href={process.env.REACT_APP_FACEBOOK_PROFILE} target="_blank" rel="noreferrer"><FaFacebookF /></a></div>
                            <div className="link"><a href={process.env.REACT_APP_LINKEDIN_PROFILE} target="_blank" rel="noreferrer"><FaLinkedinIn /></a></div>
                            <div className="link"><a href={process.env.REACT_APP_INSTAGRAM_PROFILE} target="_blank" rel="noreferrer"><FaInstagram /></a></div>
                        </div>
                    </div>

                </div>
            </div>
            {/* Form Section starts here */}
            <div className="formBx">
                <form method='POST' action={process.env.REACT_APP_FORM_URL} onSubmit={handleSubmit}>
                    {nameError === true ? <p className="error-message">Required: 'First Name' 'Last Name'</p> : null}
                    <input type="text"
                        onInput={delayWidget}
                        value={name}
                        name="name"
                        onChange={(e) => {
                            setName(e.target.value);
                            validateInput({ value: e.target.value, type: 'name' });
                        }}
                        placeholder="Full Name (Required)"
                    />

                    {emailError === true ? <p className="error-message">Required: Fill appropriate email address.</p> : null}
                    <input type="email"
                        value={email}
                        name="email"
                        onChange={(e) => {
                            setEmail(e.target.value);
                            validateInput({ value: e.target.value, type: 'email' })
                        }}
                        placeholder="Email (Required)"
                    />

                    {phoneError === true ? <p className="error-message">Input should contain numbers only!</p> : null}
                    <PhoneInput
                        containerClass={'contact-phone-container'}
                        country={'ng'}
                        placeholder={'Phone Number'}
                        value={phoneNumber}
                        inputProps={{ maxLength: '17', name: "phoneNumber", type: "text" }}
                        onChange={phoneNumber => setPhoneNumber(phoneNumber)}
                        isValid={(inputNumber, country, countries) => {
                            return countries.some((country) => {
                                return startsWith(inputNumber, country.dialCode) || startsWith(country.dialCode, inputNumber);
                            });
                        }}
                    />

                    {subjectError === true ? <p className="error-message">Subject should be at least five characters!</p> : null}
                    <input type="subject"
                        value={subject}
                        name="_subject"
                        onChange={(e) => {
                            setSubject(e.target.value);
                            validateInput({ value: e.target.value, type: 'subject' })
                        }}
                        placeholder="Subject (Required)"
                    />

                    {messageError === true ? <p className="error-message">Message should contain at least ten characters!</p> : null}
                    <textarea value={message}
                        name="message"
                        onChange={(e) => {
                            setMessage(e.target.value);
                            validateInput({ value: e.target.value, type: 'message' })
                        }}
                        placeholder="Your Message (Required)">
                    </textarea>

                    {/* Google reCAPTCHA component */}
                    <div className="recaptcha-section">
                        {
                            timeRecaptcha ?
                            <div>
                                <Recaptcha
                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                    render="explicit"
                                    onloadCallback={onloadCallback}
                                    verifyCallback={verifyCallback}
                                />
                                </div>
                                : null
                        }
                    </div>

                    {/* Form Submit Button */}
                    {!hidden ?
                        status === 'SUCCESS' ?
                            null
                            : <button type="submit">Submit</button>
                        : null
                    }

                    {/* Display messages*/}
                    {status === 'SUBMITTING' ? <p className="submit-message">Submitting...</p> : null}
                    {status === 'FAIL' ? <p className="fail-message">Network Error: Please check your connection and try again.</p> : null}
                    {status === 'SUCCESS' ? <p className="success-message">Form successfully submitted. To submit another form, please type in the form field.</p> : null}
                    {status === 'EMPTY' ? <p className="empty-form-error">Please fill all form fields appropiately.</p> : null}
                    {status === 'INVALID' ? <p className="invalid-phone-message">Invalid phone input: Please enter correct phone number.</p> : null}
                    {status === 'ERROR' ? <p className="fail-message">{serviceErrorMessage}</p> : null}
                    {status === 'NOT_VERIFIED' ? <p className="fail-message">Error: Please verifiy that you are human.</p> : null}
                </form>
            </div >
        </div >
    )
}
export default SortInputFields;