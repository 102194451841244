import React, { useEffect, useRef, useState } from 'react'
import Navigation from './components/navigation/Navigation';
import Home from './components/home/Home';
import Contact from './components/contact/Contact';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import Footer from './components/home/Footer';
import HowItWorks from './components/how-it-works/HowItWorks';
import ScrollToTop from './components/scroll-to-top/ScrollToTop';
import About from './components/about/About';
import Policy from './components/privacy-policy/PrivacyPolicy';
import Terms from './components/terms-of-service/TermsOfService';
import CookiePolicy from './components/cookie-policy/CookiePolicy';

function App() {
  const [changeBg, setChangeBg] = useState(false)
  const [offsetY, setOffsetY] = useState(0);
  const homeRef = useRef(null)
  const handleScroll = () => {

    setOffsetY(window.pageYOffset)
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    
    return () => window.removeEventListener("scroll", handleScroll);
  }, [changeBg])

  return (
    <div className="App">
      <Navigation homeRef={homeRef} setChangeBg={setChangeBg} offsetY={offsetY} />
      <Switch>
        <Route exact path="/" activeClassName="active" render={(props) => <Home homeRef={homeRef} changeBg={changeBg} offsetY={offsetY} />} />
        <Route exact path="/how-it-works" activeClassName="active" render={(props) => <HowItWorks changeBg={changeBg} />} />
        <Route exact path="/about" activeClassName="active" render={(props) => <About changeBg={changeBg} />} />
        <Route exact path="/contact" activeClassName="active" render={(props) => <Contact />} />
        <Route exact path="/privacy-policy" activeClassName="active" render={(props) => <Policy changeBg={changeBg} />} />
        <Route exact path="/terms" activeClassName="active" render={(props) => <Terms changeBg={changeBg} />} />
        <Route exact path="/cookie-policy" activeClassName="active" render={(props) => <CookiePolicy  changeBg={changeBg} />} />
      </Switch>
      <ScrollToTop />
      <Footer />
    </div>
  );
}

export default App;
