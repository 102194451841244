import React from 'react';
import '../../assets/css/HowItWorks.css';

const MainBanner =()=>{
    return(
        <div id="main-banner" className="how-it-works-overall">
            <div className="container">
                <div className="how-it-works-heading-wrapper">
                    <div className="how-it-works-title">
                        <h2>How Our App Works</h2>
                    </div>
                    <div className="how-it-works-context">
                        <p>Providing a tech-driven, affordable, convenient, reliable and safe bus transportation is what we're all about</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default MainBanner;