import React from 'react';
import '../../assets/css/HowItWorks.css';

const MainHeading  =()=>{
    return(
        <div id="main-banner" className="about-overall">
            <div className="container">
                <div className="about-heading-wrapper">
                    <div className="about-title">
                        <h2>Rewriting the African Everyday Commute Story</h2>
                    </div>
                    <div className="about-context">
                        <p>
                            Changing the prevalent experience of inadequacy and inefficiency of the traditional 
                            transportation system in Africa — one city a time
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default MainHeading;