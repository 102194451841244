import React,{ useEffect, useRef } from 'react'
import flexibility from '../../assets/icons/flexibility.svg';
import accountability from '../../assets/icons/accountability.svg';
import consistency from '../../assets/icons/consistency.svg';
import excellence from '../../assets/icons/excellence.svg';
import timeliness from '../../assets/icons/wait_time.svg';

const VisionAndMission = ({changeBg}) => {
    const visionRef = useRef(null)
  useEffect(()=>{
      if(changeBg){
          visionRef.current.style.background = "#383838"
      } else{
          visionRef.current.style.background = `#f1f1f1`
      }
  })

    return (
        <div ref={visionRef} className="mission-vision-overall">
            <div className="container">
                <div className="mission-vision-container">
                    <div className="mission-vision-box">
                        <div className="vision">
                            <h3 className="intro-title">Our Vision</h3>
                            <p className="intro-content">
                                To create an unbeatable and sustainable offering through tech-enabled public transportation 
                                services across major cities in Nigeria and Africa.
                            </p>
                        </div>
                        <div className="mission">
                            <h3 className="intro-title">Our Mission</h3>
                            <p className="intro-content">
                                To bring value to the People and Economies where we operate by providing improved, efficient, 
                                convenient, safer, and highly available (on-demand) intra/inter-city transportation services through 
                                technology driven processes, excellent customer experience, and at market-competitive rates.
                            </p>
                        </div>
                    </div>

                    <div className="values-wrapper">
                            <h3 className="intro-title">Our '<span style={{color: "var(--buttonPrimary)"}}>FACET</span>' Values</h3>
                            <div className="values-container">
                                <div className="values-box">
                                    <div className="image-holder">
                                        <img className="image-resize" src={flexibility} alt="flexibility" />
                                    </div>
                                    <div className="context">
                                        <h3>Flexibility</h3>
                                        <p>We adapt to the ever changing user and market needs.</p>
                                    </div>
                                </div>
                                <div className="values-box">
                                    <div className="image-holder">
                                        <img className="image-resize" src={accountability} alt="accountability" />
                                    </div>
                                    <div className="context">
                                        <h3>Accountability</h3>
                                        <p>Ownership — We take ownership of service.</p>
                                    </div>
                                </div>
                                <div className="values-box">
                                    <div className="image-holder">
                                        <img className="image-resize" src={consistency} alt="consistency" />
                                    </div>
                                    <div className="context">
                                        <h3>Consistency</h3>
                                        <p>We maintain our quality, dedication, alpha objectives, and sense of innovation.</p>
                                    </div>
                                </div>
                                <div className="values-box">
                                    <div className="image-holder">
                                        <img className="image-resize" src={excellence} alt="excellence" />
                                    </div>
                                    <div className="context">
                                        <h3>Excellence</h3>
                                        <p>We are deliberate about offering the best results and customer experience.</p>
                                    </div>
                                </div>
                                <div className="values-box">
                                    <div className="image-holder">
                                        <img className="image-resize" src={timeliness} alt="timeliness" />
                                    </div>
                                    <div className="context">
                                        <h3>Timeliness</h3>
                                        <p>We do all of the above with a sense of good timing.</p>
                                    </div>
                                </div>
                            </div>
                            
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VisionAndMission
