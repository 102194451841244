
import React from 'react'
import { withRouter } from 'react-router-dom'
import Banner from './Banner'
import Feature from './Feature'
import Pricing from './Pricing'
import Milestone from './Milestone'
import Testimonial from './Testimonial'
import Screenshots from './Screenshots'
import Download from './Downloads'
import Newsletter from './Newsletter'




const Home = ({homeRef, offsetY, changeBg}) => {
    
    const newsletterDisplayObject = {
        heading:'Join Our Wait List',
        message:'Sign up to get early access — Get great discounts on rides, and free gifts when you ride with us.'
    }

    return (
        <div ref={homeRef}>
            <Banner offsetY={offsetY} changeBg={changeBg} />
            <Feature changeBg={changeBg}/>
            <Milestone/>
            <Pricing />
            <Testimonial/>
            <Screenshots />
            <div className="howItWorksDownload">
            <Download />
            </div>
            <Newsletter newsletterDisplayObject={newsletterDisplayObject} />
        </div>
    )
}

export default withRouter(Home)
